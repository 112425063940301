import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    signin:signin,
    user_count:{
      loaded:false,
      total:"-",
      trial:"-",
      monthly:"-",
      yearly:"-"
    },
    sales:{
      loaded:false,
      year:"-",
      month:"-",
      sales:"-",
    },
    monitor_count:{
      loaded:false,
      total:"-",
      _1min:"-",
      _10min:"-",
      _60min:"-",
      inactive:{
        total:"-",
        _1min:"-",
        _10min:"-",
        _60min:"-",
      }
    },
    monitor_v2_count:[],
    targets:{
      fetched:null,
      targets:[],
      start:null,
      end:null
    }
  },
  mutations: {
    sign_in(state) {
      state.signin = true;
    },
    sign_out(state) {
      state.signin = false;
    },
    user_count (state, value){
      state.user_count = value;
    },
    sales (state, value){
      state.sales = value;
    },
    monitor_count (state, value){
      state.monitor_count = value;
    },
    monitor_v2_count_add (state, value){
      const tmp = JSON.parse(JSON.stringify(state.monitor_v2_count));
      tmp.push(value);
      state.monitor_v2_count = tmp;
    },
    monitor_v2_count_reset (state, value){
      state.monitor_v2_count = [];
    },
    targets_fetched (state, value){
      state.targets.fetched = value;
    },
    targets_targets (state, value){
      state.targets.targets = value;
    },
    targets_startend (state, value){
      state.targets.start = value.start;
      state.targets.end = value.end;
    }
  },
  actions: {
  },
  modules: {
  }
})
